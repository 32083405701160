import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsGreenhouse } from '@shared/interfaces/integrations/integrations.greenhouse.interface';

export class IntegrationsGreenhouse implements IIntegrationsGreenhouse {
  public static readonly TYPE: string = String(
    'ExternalAuthorisations::Greenhouse'
  );

  public id: APIuuid;
  public site_url: string;
  public user_id: string;
  public api_key: string;
  public username: string;
  public password: string;

  constructor(partial: Partial<IIntegrationsGreenhouse>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: IntegrationsGreenhouse.TYPE,
        id: this.id,
        attributes: {
          site_url: this.site_url,
          user_id: this.user_id,
          api_key: this.api_key
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IIntegrationsGreenhouse {
    const data = res.data;
    const types = [IntegrationsGreenhouse.TYPE, 'ExternalAuthorisation'];

    if (!types.includes(data?.type)) {
      throw new Error(
        `There was a problem parsing ${IntegrationsGreenhouse.TYPE} API data`
      );
    }

    return new IntegrationsGreenhouse({
      id: data.id,
      ...data.attributes
    });
  }
}
