import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsTargetRecruit } from '@shared/interfaces/integrations/integrations.target-recruit.interface';

export class IntegrationsTargetRecruit implements IIntegrationsTargetRecruit {
  public static readonly TYPE: string = String(
    'ExternalAuthorisations::TargetRecruit'
  );

  public id: APIuuid;
  public site_url: string;
  public client_id: string;
  public client_secret: string;
  public username: string;
  public password: string;
  public security_token: string;
  public candidate_record_type_id: string;

  public get apiData(): IAPIData {
    return {
      data: {
        type: IntegrationsTargetRecruit.TYPE,
        id: this.id,
        attributes: {
          site_url: this.site_url,
          client_id: this.client_id,
          client_secret: this.client_secret,
          username: this.username,
          password: this.password,
          security_token: this.security_token,
          candidate_record_type_id: this.candidate_record_type_id
        }
      }
    } as IAPIData;
  }

  constructor(partial: Partial<IIntegrationsTargetRecruit>) {
    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): IntegrationsTargetRecruit {
    const data = res.data;
    const types = [IntegrationsTargetRecruit.TYPE, 'ExternalAuthorisation'];

    if (!types.includes(data?.type)) {
      throw new Error(
        `There was a problem parsing ${IntegrationsTargetRecruit.TYPE} API data`
      );
    }

    return new IntegrationsTargetRecruit({
      id: data.id,
      ...data.attributes
    });
  }
}
