import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import jwtDecode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanDeactivate<any> {
  @LocalStorage() public isSubAccount: boolean;
  @LocalStorage() public canManageSubAccount: boolean;

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.processQueryParams(route.queryParams);

    const token = this._commonEnvironments.getToken();

    if (!!token) {
      const payload: IPayload = jwtDecode(token);
      this.canManageSubAccount = !!payload.recruiter_id;

      if (this.hasPermission(payload)) {
        return true;
      } else {
        this._commonEnvironments.cleanStorage();
        this._router.navigate(['/login'], {
          queryParams: {
            returnUrl: state.url
          }
        });

        return false;
      }
    }

    this._commonEnvironments.cleanStorage();
    this._router.navigate(['/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });

    return false;
  }

  hasPermission(payload: IPayload) {
    this.isSubAccount = payload.is_sub_account;
    const roles = payload.roles
    const isParentRoot = this.isSubAccount && payload.recruiter_id === null
    const hasRole = roles.every((role) => ['root_recruiter', 'recruiter'].includes(role))

    if(isParentRoot || hasRole) {
      return true;
    }

    return false;
  }

  public canDeactivate(): boolean {
    return true;
  }

  private processQueryParams(queryParams: { [key: string]: any }) {
    const token = queryParams['token'];
    if (!!token) {
      this._commonEnvironments.setToken('recruiter-token', token);
    }
  }
}
