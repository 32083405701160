import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { configurationEnvironment } from '@configuration/environment/environment.configuration';
import { environment } from '@environment/environment';
import { IIntegrationsJobAdder } from '@shared/interfaces/integrations/integrations.jobadder.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsJobAdderService {
  public CLIENT_ID: string =
    configurationEnvironment.INTEGRATIONS.JOBADDER.client_id;
  public readonly REDIRECT_URI: string = `${location.protocol}//${location.host}/settings/jobadder`;
  private readonly ENDPOINT: string = String(
    `${environment.SERVER_ENDPOINT}/api/jobadder`
  );

  constructor(private readonly _http: HttpClient) {}

  public authorize(code: string, state: string): Observable<any> {
    return this._http.post<any>(
      `${environment.SERVER_ENDPOINT}/api/auth/jobadder`,
      {
        code,
        state
      }
    );
  }

  public delete(): Observable<any> {
    return this._http.delete(this.ENDPOINT);
  }

  public generateOAuthLink(params: IIntegrationsJobAdder): string {
    const oauthLink: string =
      'https://id.jobadder.com/connect/authorize?' +
      'response_type=code&' +
      `client_id=${this.CLIENT_ID}&` +
      'scope=read write offline_access partner_ui_action&' +
      `redirect_uri=${this.REDIRECT_URI}&` +
      `state=${this.base64Encode({
        ...params
      })}`;

    return oauthLink;
  }

  public get(type: string, id: string): Observable<any> {
    return this._http.get(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        entity_id: id
      }
    });
  }

  public patchStatus(body: any): Observable<any> {
    return this._http.patch(
      `${this.ENDPOINT}/entity/${body.entity_id}/status`,
      body
    );
  }

  private base64Encode(obj: { [key: string]: number | string }): string {
    return window.btoa(JSON.stringify(obj));
  }
}
