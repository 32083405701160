import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { ICustomNotification } from '@shared/interfaces/custom-notification.interface';
import { CustomNotification } from '@shared/models/custom-notification.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HiringFirmsCustomNotificationsService {
  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/hiring_firms`
  );

  constructor(private readonly _http: HttpClient) {}

  public get(qs: any): Observable<ICustomNotification> {
    const id = qs.id;

    let params: HttpParams = new HttpParams();
    params = params.set('notification_type', String(qs.notification_type));

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}/custom_notifications`, {
        params
      })
      .pipe(map((res: IAPIData) => CustomNotification.fromAPI(res)));
  }

  // TO DO REFACTOR THAT TO leads_notifications/:id
  public patch(id: APIuuid, body: IAPIData): Observable<ICustomNotification> {
    return this._http
      .patch(`${this.ENDPOINT_V1}/${id}/custom_notifications`, body)
      .pipe(map((res: IAPIData) => CustomNotification.fromAPI(res)));
  }
}
