import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { configurationEnvironment } from '@configuration/environment/environment.configuration';
import { environment } from '@environment/environment';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IntegrationsBullhorn } from '@shared/models/integrations/integrations.bullhorn.model';
import { IntegrationsERecruit } from '@shared/models/integrations/integrations.erecruit.model';
import { IntegrationsGreenhouse } from '@shared/models/integrations/integrations.greenhouse.model';
import { IntegrationsJobAdder } from '@shared/models/integrations/integrations.jobadder.model';
import { IntegrationsJobDiva } from '@shared/models/integrations/integrations.jobdiva.model';
import { IntegrationsSalesforceIsv } from '@shared/models/integrations/integrations.salesforce-isv.model';
import { IntegrationsSalesforce } from '@shared/models/integrations/integrations.salesforce.model';
import { IntegrationsTargetRecruit } from '@shared/models/integrations/integrations.target-recruit.model';
import { IntegrationsBullhornService } from '@shared/services/integrations/integrations.bullhorn.service';
import { IntegrationsErecruitService } from '@shared/services/integrations/integrations.erecruit.service';
import { IntegrationsGreenhouseService } from '@shared/services/integrations/integrations.greenhouse.service';
import { IntegrationsJobAdderService } from '@shared/services/integrations/integrations.jobadder.service';
import { IntegrationsJobDivaService } from '@shared/services/integrations/integrations.jobdiva.service';
import { IntegrationsSalesforceIsvService } from '@shared/services/integrations/integrations.salesforce-isv.service';
import { IntegrationsSalesforceService } from '@shared/services/integrations/integrations.salesforce.service';
import { IntegrationsTargetRecruitService } from '@shared/services/integrations/integrations.target-recruit.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-integrations',
  templateUrl: './modals.integrations.component.html'
})
export class ModalsIntegrationsComponent
  extends ModalDirective<any>
  implements OnInit
{
  @Output()
  public getHiringFirmIntegrations: EventEmitter<any> = new EventEmitter();

  @SessionStorage() private hiringFirm: IHiringFirm;

  public ENDPOINTS_ASSETS = String(environment.ENDPOINTS.ASSETS);
  public id: string;
  public credentials:
    | IntegrationsBullhorn
    | IntegrationsJobAdder
    | IntegrationsJobDiva
    | IntegrationsERecruit
    | IntegrationsSalesforce
    | IntegrationsSalesforceIsv
    | IntegrationsTargetRecruit;
  public isButtonsDisabled = Boolean(false);
  public description: string;
  public isSetup = Boolean(false);
  public isAvailable = Boolean(false);
  public entry: FormGroup;
  public title: string;
  public logo: string;
  public config: any;
  public webhookUrl: string;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toast: ToastService,
    private readonly _jobadder: IntegrationsJobAdderService,
    private readonly _salesforce: IntegrationsSalesforceService,
    private readonly _salesforce_isv: IntegrationsSalesforceIsvService,

    // Integrations services
    public readonly _erecruit: IntegrationsErecruitService,
    public readonly _jobdiva: IntegrationsJobDivaService,
    public readonly _bullhorn: IntegrationsBullhornService,
    public readonly _target_recruit: IntegrationsTargetRecruitService,
    public readonly _greenhouse: IntegrationsGreenhouseService
  ) {
    super();
  }

  public get submitKey() {
    let submitKey = 'BUTTONS.SUBMIT';
    switch (this.id) {
      case 'salesforce_isv':
      case 'salesforce':
      case 'jobadder':
        submitKey = 'MODALS.INTEGRATIONS.JOBADDER.SUBMITKEY';
        break;

      case 'target_recruit':
      case 'bullhorn':
      case 'erecruit':
      case 'jobdiva':
      case 'greenhouse':
      default:
        submitKey = 'BUTTONS.SUBMIT';
        break;
    }

    return submitKey;
  }

  ngOnInit(): void {
    this.openModal.subscribe((res: any) => {
      this.id = res.id;
      this.logo = res.logo;
      this.isSetup = res.isSetup;
      this.isAvailable = res.isAvailable;

      if (!environment.IS_PRODUCTION) {
        switch (this.id) {
          case 'bullhorn':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.BULLHORN
            );
            break;

          case 'erecruit':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.ERECRUIT
            );
            break;

          case 'jobdiva':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.JOBDIVA
            );
            break;

          case 'jobadder':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.JOBADDER
            );
            break;

          case 'salesforce':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.SALESFORCE
            );
            break;

          case 'salesforce_isv':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.SALESFORCE_ISV
            );
            break;

          case 'target_recruit':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.TARGET_RECRUIT
            );
            break;

          case 'greenhouse':
            this.entry.patchValue(
              configurationEnvironment.INTEGRATIONS.GREENHOUSE
            );
            break;
        }
      }

      this.setVariables();
    });

    this.createForm();
  }

  public delete(): void {
    const url = `DELETE ${this.id}`;

    this[`_${this.id}`].delete().subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this.onDismiss();

        this.credentials = null;
        this.closeModal.next();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onDismiss() {
    this.entry.reset();

    const controls = this.entry.controls;

    Object.entries(controls).map((c) => {
      c[1].setValidators([]);
      c[1].updateValueAndValidity();
    });

    this.getHiringFirmIntegrations.emit();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (valid) {
      this.isButtonsDisabled = true;
      this.entry.disable();

      switch (this.id) {
        case 'bullhorn':
          this.credentials = new IntegrationsBullhorn({
            ...this.credentials,
            ...value
          });
          break;

        case 'erecruit':
          this.credentials = new IntegrationsERecruit({
            ...this.credentials,
            ...value
          });
          break;

        case 'jobadder':
          this.credentials = new IntegrationsJobAdder({
            ...this.credentials,
            ...value,
            hiring_firm_id: this.hiringFirm.id,
            redirect_uri: this._jobadder.REDIRECT_URI
          });
          break;

        case 'jobdiva':
          this.credentials = new IntegrationsJobDiva({
            ...this.credentials,
            ...value
          });
          break;

        case 'salesforce':
          this.credentials = new IntegrationsSalesforce({
            ...this.credentials,
            ...value,
            hiring_firm_id: this.hiringFirm.id,
            redirect_uri: this._salesforce.REDIRECT_URI
          });
          break;

        case 'salesforce_isv':
          this.credentials = new IntegrationsSalesforceIsv({
            ...this.credentials,
            ...value,
            hiring_firm_id: this.hiringFirm.id,
            redirect_uri: this._salesforce_isv.REDIRECT_URI
          });
          break;

        case 'target_recruit':
          this.credentials = new IntegrationsTargetRecruit({
            ...this.credentials,
            ...value
          });
          break;

        case 'greenhouse':
          this.credentials = new IntegrationsGreenhouse({
            ...this.credentials,
            ...value
          });
          break;
      }

      if (
        this.id === 'jobadder' ||
        this.id === 'salesforce' ||
        this.id === 'salesforce_isv'
      ) {
        const url = this[`_${this.id}`].generateOAuthLink(
          this.credentials as any
        );

        window.open(url, '_self');
      } else {
        const url = `POST ${this.id}`;
        this[`_${this.id}`].authorize(this.credentials).subscribe(
          (res: any) => {
            this._logger.info(this.constructorName, url, res);
            this._toast.success(`${this.id} added`);

            this.entry.enable();
            this.isButtonsDisabled = false;
            this.credentials = null;

            this.resetModal();
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);
            this._toast.error(`${this.id} failed`);

            this.errors = err;
            this.isButtonsDisabled = false;
            this.entry.enable();
          }
        );
      }
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      // erecruit
      site_url: [''],
      attachment_type_id: [''],
      folder_group_id: [''],
      ad_source_id: [''],
      status_id: [''],
      requirement_id: [''],
      department_id: [''],

      // Bullhorn, erecruit, target recruit
      client_id: [''],
      client_secret: [''],
      username: [''],
      password: [''],

      // target recruit
      candidate_record_type_id: [''],
      security_token: [''],

      // Greenhouse
      api_key: [''],
      user_id: ['']
    });
  }

  protected setVariables() {
    this.title = `INTEGRATIONS.${this.id.toUpperCase()}.TITLE`;
    this.description = `INTEGRATIONS.${this.id.toUpperCase()}.DESCRIPTION`;

    const siteUrl = this.entry.controls['site_url'];
    const attachmentTypeId = this.entry.controls['site_url'];
    const folderGroupId = this.entry.controls['folder_group_id'];
    const adSourceId = this.entry.controls['ad_source_id'];
    const statusId = this.entry.controls['status_id'];
    const requirementId = this.entry.controls['requirement_id'];
    const candidateRecordTypeId =
      this.entry.controls['candidate_record_type_id'];
    const securityToken = this.entry.controls['security_token'];
    const departmentId = this.entry.controls['department_id'];

    const clientId = this.entry.controls['client_id'];
    const clientSecret = this.entry.controls['client_secret'];

    const username = this.entry.controls['username'];
    const password = this.entry.controls['password'];
    const userId = this.entry.controls['user_id'];
    const apiKey = this.entry.controls['api_key'];

    switch (this.id) {
      case 'bullhorn':
        siteUrl.setValidators([]);
        attachmentTypeId.setValidators([]);
        folderGroupId.setValidators([]);
        adSourceId.setValidators([]);
        statusId.setValidators([]);
        requirementId.setValidators([]);
        candidateRecordTypeId.setValidators([]);
        securityToken.setValidators([]);
        departmentId.setValidators([]);

        clientId.setValidators([Validators.required]);
        clientSecret.setValidators([Validators.required]);

        username.setValidators([Validators.required]);
        password.setValidators([Validators.required]);
        break;

      case 'erecruit':
        siteUrl.setValidators([Validators.required]);
        attachmentTypeId.setValidators([Validators.required]);
        folderGroupId.setValidators([Validators.required]);
        adSourceId.setValidators([Validators.required]);
        statusId.setValidators([Validators.required]);
        requirementId.setValidators([Validators.required]);
        candidateRecordTypeId.setValidators([]);
        securityToken.setValidators([]);
        departmentId.setValidators([]);

        clientId.setValidators([Validators.required]);
        clientSecret.setValidators([Validators.required]);

        username.setValidators([]);
        password.setValidators([]);
        break;

      case 'jobdiva':
        siteUrl.setValidators([]);
        attachmentTypeId.setValidators([]);
        folderGroupId.setValidators([]);
        adSourceId.setValidators([]);
        statusId.setValidators([]);
        requirementId.setValidators([]);
        candidateRecordTypeId.setValidators([]);
        securityToken.setValidators([]);
        departmentId.setValidators([]);

        clientId.setValidators([Validators.required]);
        clientSecret.setValidators([]);

        username.setValidators([Validators.required]);
        password.setValidators([Validators.required]);
        break;

      case 'target_recruit':
        siteUrl.setValidators([Validators.required]);
        attachmentTypeId.setValidators([]);
        folderGroupId.setValidators([]);
        adSourceId.setValidators([]);
        statusId.setValidators([]);
        requirementId.setValidators([]);
        candidateRecordTypeId.setValidators([Validators.required]);
        securityToken.setValidators([]);
        departmentId.setValidators([]);

        clientId.setValidators([Validators.required]);
        clientSecret.setValidators([Validators.required]);

        username.setValidators([Validators.required]);
        password.setValidators([Validators.required]);
        break;

      case 'greenhouse':
        siteUrl.setValidators([Validators.required]);
        attachmentTypeId.setValidators([]);
        folderGroupId.setValidators([]);
        adSourceId.setValidators([]);
        statusId.setValidators([]);
        requirementId.setValidators([]);
        candidateRecordTypeId.setValidators([]);
        securityToken.setValidators([]);
        departmentId.setValidators([]);

        clientId.setValidators([]);
        clientSecret.setValidators([]);

        username.setValidators([]);
        password.setValidators([]);

        userId.setValidators([Validators.required]);
        apiKey.setValidators([Validators.required]);

        this._greenhouse.getWebhook().subscribe((res: any) => {
          this.webhookUrl = res.url;
        });

        break;
    }

    siteUrl.updateValueAndValidity();
    attachmentTypeId.updateValueAndValidity();
    folderGroupId.updateValueAndValidity();
    adSourceId.updateValueAndValidity();
    statusId.updateValueAndValidity();
    requirementId.updateValueAndValidity();
    candidateRecordTypeId.updateValueAndValidity();
    securityToken.updateValueAndValidity();
    departmentId.updateValueAndValidity();

    clientId.updateValueAndValidity();
    clientSecret.updateValueAndValidity();

    username.updateValueAndValidity();
    password.updateValueAndValidity();
  }
}
