import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IIntegrationsBullhorn } from '@shared/interfaces/integrations/integrations.bullhorn.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsBullhornService {
  private readonly ENDPOINT: string = String(
    `${environment.SERVER_ENDPOINT}/api/bullhorn`
  );

  constructor(private readonly _http: HttpClient) {}

  public authorize(params: IIntegrationsBullhorn): Observable<any> {
    return this._http.post<any>(
      `${environment.SERVER_ENDPOINT}/api/auth/bullhorn`,
      params
    );
  }

  public delete(): Observable<any> {
    return this._http.delete(this.ENDPOINT);
  }

  public get(type: string, id: string): Observable<any> {
    return this._http.get(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        entity_id: id
      }
    });
  }

  public put(type: string, id: string): Observable<any> {
    return this._http.put(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        id
      }
    });
  }
}
