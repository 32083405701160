import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IReferenceRelationship } from '@shared/interfaces/reference-relationship.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { ReferenceRelationship } from '@shared/models/reference-relationship.model';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { HiringFirmsService } from '@shared/services/hiring-firms.service';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferenceRelationshipsService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/reference_relationships`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _hiring_firms: HiringFirmsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: APIuuid): Observable<IReferenceRelationship> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => ReferenceRelationship.fromAPI(res)));
  }

  public get(): Observable<IReferenceRequest[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => ReferenceRequest.fromAPIArray(res)));
  }

  public find(id: APIuuid): Observable<IReferenceRelationship> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => ReferenceRelationship.fromAPI(res)));
  }

  public patch(body: IAPIData): Observable<IReferenceRelationship> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => ReferenceRelationship.fromAPI(res)));
  }

  public post(body: any): Observable<IReferenceRelationship> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => ReferenceRelationship.fromAPI(res)));
  }

  public resolve(): Observable<IReferenceRelationship[]> {
    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);
    return this._hiring_firms.getReferenceRelationships(
      payload.hiring_firm_id,
      true
    );
  }
}
