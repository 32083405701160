import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { configurationEnvironment } from '@configuration/environment/environment.configuration';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsSalesforceIsv } from '@shared/interfaces/integrations/integrations.salesforce-isv.interface';
import { IntegrationsSalesforceIsv } from '@shared/models/integrations/integrations.salesforce-isv.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsSalesforceIsvService {
  public CLIENT_ID: string =
    configurationEnvironment.INTEGRATIONS.SALESFORCE_ISV.client_id;
  public readonly REDIRECT_URI: string = `${location.protocol}//${location.host}/settings/salesforce_isv`;
  private readonly ENDPOINT: string = String(
    `${environment.SERVER_ENDPOINT}/api/services/salesforce_isv`
  );

  constructor(private readonly _http: HttpClient) {}

  // TO DO
  // I THINK I NEED TO CHANGE THE CLIENT ID FOR APP MANAGER
  public generateOAuthLink(params: IIntegrationsSalesforceIsv): string {
    const oauthLink: string =
      'https://login.salesforce.com/services/oauth2/authorize?' +
      'response_type=code&' +
      `client_id=${this.CLIENT_ID}&` +
      `redirect_uri=${this.REDIRECT_URI}&` +
      `state=${this.base64Encode({
        ...params
      })}`;

    return oauthLink;
  }

  public authorize(code: string, state: string): Observable<any> {
    return this._http.post<any>(`${this.ENDPOINT}/auth`, {
      code,
      state
    });
  }

  public delete(): Observable<IIntegrationsSalesforceIsv> {
    return this._http
      .delete(this.ENDPOINT)
      .pipe(map((res: IAPIData) => IntegrationsSalesforceIsv.fromAPI(res)));
  }

  public get(type: string, id: string): Observable<any> {
    return this._http.get(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        entity_id: id
      }
    });
  }

  public put(type: string, id: string): Observable<any> {
    return this._http.put(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        id
      }
    });
  }

  private base64Encode(obj: { [key: string]: number | string }): string {
    return window.btoa(JSON.stringify(obj));
  }
}
