export const configurationEnvironment = {
  GOOGLE_ANALYTICS: {
    domain: 'auto',
    trackingId: '',
    GTM: ''
  },
  INTEGRATIONS: {
    BULLHORN: {
      client_id: ''
    },
    ERECRUIT: {
      client_id: ''
    },
    SALESFORCE: {
      client_id: ''
    },
    SALESFORCE_ISV: {
      client_id: ''
    },
    TARGET_RECRUIT: {
      client_id: ''
    },
    JOBADDER: {
      client_id: ''
    },
    JOBDIVA: {
      client_id: ''
    },
    GREENHOUSE: {
      username: ''
    }
  },
  STRIPE: {
    PUBLISHABLE_KEY: 'pk_test_NiGtkBKV9pSRLLtjExE2VzkU',
    CARD: {
      name: 'Lorem Ipsum',
      number: 4242424242424242,
      cvc: 123,
      exp_month: 10,
      exp_year: 20
    }
  }
};
