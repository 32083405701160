const SERVER_ENDPOINT = 'https://staging.api.vitay.io';
import packageInfo from '../../package.json';
import { VALIDITY_SCREENING_CONFIG } from './validity.environment';

export const environment = {
  HELP_SCOUT_ID: '2dd0e0c9-b62d-45d2-8811-e3f5fa17612d',
  VERSION: packageInfo.version,
  FB_PIXEL: {
    IS_ENABLED: false,
    PIXEL_ID: '246202623555291'
  },
  AIRBRAKE: {
    ENVIRONMENT: 'dev',
    ADMIN: {
      PROJECT_ID: 215349,
      PROJECT_KEY: '2cbdc2e6538edf12d8c85f4a5061aa92'
    },
    ANALYTICS: {
      PROJECT_ID: 326842,
      PROJECT_KEY: 'f5ebf9a1ea29125a45d7eb49b92599a8'
    },
    APP: {
      PROJECT_ID: 200605,
      PROJECT_KEY: '9ec78c462fc0cf951bb02dbce33d9378'
    },
    CONFIGURATION: {
      PROJECT_ID: 326844,
      PROJECT_KEY: '44dddb2dfcca5c8709462940faa6fd36'
    },
    FEEDBACK: {
      PROJECT_ID: 326854,
      PROJECT_KEY: 'd95dd8ca4565661433c55e2c4149bde2'
    },
    REFERENCES: {
      PROJECT_ID: 201989,
      PROJECT_KEY: '15edd126684157319230a6364996a6c9'
    },
    SIGNUP: {
      PROJECT_ID: 419004,
      PROJECT_KEY: '0ce67c87f69fca8c846f9fec63eed578'
    }
  },
  ENDPOINTS: {
    SOCKET: 'wss://staging.api.vitay.io/cable',
    ASSETS: 'https://staging.assets.vitay.io',
    BACK_END: {
      V1: `${SERVER_ENDPOINT}/api/v1`,
      V2: `${SERVER_ENDPOINT}/api/v2`,
      V3: `${SERVER_ENDPOINT}/api/v3`
    },
    FRONT_END: {
      ADMIN: 'https://staging.admin.vitay.io',
      ANALYTICS: 'https://staging.analytics.vitay.io',
      APP: 'https://staging.vitay-app.cloud.vitay.io',
      CONFIGURATION: 'https://staging.configuration.vitay.io',
      FEEDBACK: 'https://staging.feedback.vitay.io',
      IFRAME: 'https://staging.iframe.vitay.io',
      REFERENCES: 'https://staging.references.vitay.io'
    }
  },
  ENVIRONMENT: 'dev',
  IP_INFO: 'bd86ce9ca31822',
  IS_PRODUCTION: false,
  SERVER_ENDPOINT,
  GOOGLE_ANALYTICS: {
    GTM: ''
  },
  VALIDITY_SCREENING: {
    PRODUCTS: VALIDITY_SCREENING_CONFIG.DEV.PRODUCTS
  },
  SENTRY: {
    DNS: 'https://01d8b62059b8462ede398afd1946e549@o4508258504212480.ingest.us.sentry.io/4508264190246912',
    TRACE_URL: ['localhost', /^https:\/\/api\.vitay\.(co\.uk|io|ca)\/api\/$/]
  }
};
