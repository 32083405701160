import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsTargetRecruit } from '@shared/interfaces/integrations/integrations.target-recruit.interface';
import { IntegrationsTargetRecruit } from '@shared/models/integrations/integrations.target-recruit.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsTargetRecruitService {
  private readonly ENDPOINT: string = String(
    `${environment.SERVER_ENDPOINT}/api/services/target_recruit`
  );

  constructor(private readonly _http: HttpClient) {}

  // TO DO
  // Rewrite the workflow with OAuth workflow intead of username / password?
  // https://help.salesforce.com/articleView?id=remoteaccess_authenticate.htm&type=5
  public authorize(
    params: IIntegrationsTargetRecruit
  ): Observable<IIntegrationsTargetRecruit> {
    const body = new IntegrationsTargetRecruit(params).apiData;
    return this._http
      .post<any>(`${this.ENDPOINT}/auth`, body)
      .pipe(map((res: IAPIData) => IntegrationsTargetRecruit.fromAPI(res)));
  }

  public delete(): Observable<IIntegrationsTargetRecruit> {
    return this._http
      .delete(this.ENDPOINT)
      .pipe(map((res: IAPIData) => IntegrationsTargetRecruit.fromAPI(res)));
  }

  public get(type: string, id: string): Observable<any> {
    return this._http.get(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        entity_id: id
      }
    });
  }

  public put(type: string, id: string): Observable<any> {
    return this._http.put(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        id
      }
    });
  }
}
