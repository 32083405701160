import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { languages } from '@shared/helpers/languages.helper';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IQuestionSetTemplate } from '@shared/interfaces/question-set-template.interface';
import { IQuestion } from '@shared/interfaces/question.interface';
import { QuestionSet } from '@shared/models/question-set.model';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { QuestionSetsTemplatesService } from '@shared/services/question-sets-templates.service';
import { ToastService } from '@shared/services/toast.service';
import { SessionStorage } from 'ngx-webstorage';
import { QuestionSetTemplate } from '@shared/models/question-set-template.model';

@Component({
  selector: 'app-modals-question-sets',
  templateUrl: './modals.question-sets.component.html'
})
export class ModalsQuestionSetsComponent
  extends ModalDirective<IQuestionSet>
  implements OnInit
{
  @SessionStorage() private hiringFirm: IHiringFirm;

  public entry: FormGroup;
  public questions: IQuestion[];
  public sortableQuestions: IQuestion[];
  public errors: any = [];
  public templates: IQuestionSetTemplate[] = [];
  public filteredTemplates: IQuestionSetTemplate[] = [];

  public isFeedbackEnabled = Boolean(false);
  public isMultiLanguagesEnabled = Boolean(false);
  public isReferenceApp = Boolean(false);
  public isTemplateChecked = Boolean(false);

  public title = String('MODALS.QUESTION_SETS.ADD.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');

  public types: { label: string; value: string }[] = [
    {
      label: 'Reference',
      value: 'Reference'
    }
  ];

  public languages: any[] = languages;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _router: Router,
    private readonly _logger: LoggerService,
    private readonly _questionsSetsTemplateService: QuestionSetsTemplatesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    const currentUrl = this._router.url;
    this.isReferenceApp = currentUrl === '/question-sets';

    const defaultType = this.entry.get('identifiable_type').value;

    this.updateTemplates();
    this.updateListBasedOnType(defaultType);

    this.entry.get('identifiable_type').valueChanges.subscribe((selectedType) => {
      this.updateListBasedOnType(selectedType);
    });

    this.entry.get('is_template').valueChanges.subscribe((isTemplate) => {
      this.isTemplateChecked = isTemplate;

      if (isTemplate) {
        this.entry.get('title').disable();
        this.entry.get('title').clearValidators();

        this.entry.get('language').disable();
        this.entry.get('language').clearValidators();

        this.entry.get('template').enable();
        this.entry.get('template').setValidators([Validators.required]);
      } else {
        this.entry.get('title').enable();
        this.entry.get('title').setValidators([Validators.required, Validators.maxLength(255)]);

        this.entry.get('language').enable();
        this.entry.get('language').setValidators([Validators.required]);

        this.entry.get('template').disable();
        this.entry.get('template').clearValidators();
      }

      this.entry.get('template').updateValueAndValidity();
    });

    this.openModal.subscribe((qs: IQuestionSet) => {
      const settings = this.hiringFirm?.settings;
      this.isFeedbackEnabled =
        settings?.is_plugins_candidate_feedback_enabled ||
        settings?.is_plugins_recruiter_feedback_enabled ||
        settings?.is_plugins_pre_screening_enabled;

      this.isMultiLanguagesEnabled =
        settings?.is_plugins_multi_languages_enabled;

      if (settings?.is_plugins_candidate_feedback_enabled) {
        this.types.push(
          {
            label: 'Feedback',
            value: 'Feedback'
          }
        );
      }

      if (settings?.is_plugins_pre_screening_enabled) {
        this.types.push({
          label: 'Pre-screening',
          value: 'PreScreening'
        });
      }

      if(!this.isReferenceApp) {
        this.types = [{ label: 'Reference', value: 'Reference'},
                      { label: 'Pre-screening', value: 'PreScreening'},
                      { label: 'Feedback', value: 'Feedback'}];
      }

      if (!!qs) {
        this.questions = qs.questions as IQuestion[];
        this.sortableQuestions = this.questions.filter(
          (q: IQuestion) => q.language === qs.language
        );

        this.entry.patchValue(qs);
        this.title = 'MODALS.QUESTION_SETS.EDIT.TITLE';
        this.submitKey = 'BUTTONS.SAVE';
      } else {
        this.entry.patchValue({ language: 'en' });
      }

      this.entry.patchValue({
        hiring_firm_id: this.hiringFirm?.id
      });

      if (!this.entry.get('is_template').value) {
        this.entry.get('template').disable();
      }
    });
  }

  public onDismiss() {
    this.entry.reset();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();
      this.errors = [];

      if (value.id) {
        this.patch(value);
      } else {
        this.post(value);
      }
    }
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    }
  }

  public updateLanguage() {
    if (this.questions?.length > 0) {
      const lng = this.entry.get('language').value;
      this.sortableQuestions = this.questions.filter(
        (q: IQuestion) => q.language === lng
      );
    }
  }

  protected createForm() {
    const hiringFirmValidators = this.hiringFirm ? [Validators.required] : [];
    const languageValidators = this.hiringFirm ? [Validators.required] : [];
    const identifiableTypeValidators = this.hiringFirm ? [Validators.required] : [];

    this.entry = this._fb.group({
      id: [''],
      hiring_firm_id: ['', hiringFirmValidators],
      title: ['', [Validators.required, Validators.maxLength(255)]],
      template: [''],
      is_template: [false],
      language: ['', languageValidators],
      identifiable_type: ['Reference', identifiableTypeValidators],
      questions: ''
    });
  }

  private patch(value: IQuestionSet) {
    value.questions = this.sortableQuestions;
    value.questions.forEach((question, index) => {
      (question as IQuestion).order = index;
    });

    const qs = this.hiringFirm ? new QuestionSet(value).apiData : new QuestionSetTemplate(value).apiData;
    const id = qs.data.id;
    const url = this.hiringFirm ? `PATCH /question-sets/${id}` : `PATCH /question-sets-templates/${id}`;
    const patchService = this.hiringFirm ? this._questionSets : this._questionsSetsTemplateService;
    const successMessage = this.hiringFirm ? 'Question set updated' : 'Question set template updated';

    this.executePatchRequest(patchService, qs, url, successMessage);
  }

  private executePatchRequest(service: any, data: any, url: string, successMessage: string) {
    service.patch(data).subscribe(
      (res: IQuestionSet) => {
        this._logger.info(this.constructorName, url, res);
        this.entry.enable();
        this.resetModal(res);
        this._toast.success(successMessage);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.errors = err;
        this.entry.enable();
      }
    );
  }

  private updateListBasedOnType(selectedType: string): void {
     if (selectedType === 'Reference') {
       this.filteredTemplates = this.templates.filter(q => q.identifiable_type === 'Reference');
     };

     if (selectedType === 'Feedback') {
       this.filteredTemplates = this.templates.filter(q => q.identifiable_type === 'Feedback');
     };

     if (selectedType === 'PreScreening') {
      this.filteredTemplates = this.templates.filter(q => q.identifiable_type === 'PreScreening');
     }
  }

  private updateTemplates() {
    const filters = {
      filter: {
        key: 'qs',
        value: 'scope:all'
      }
    };

    const url = 'GET /question_sets_templates';
    this._questionsSetsTemplateService.get(filters).subscribe(
      (res: IQuestionSetTemplate[]) => {
        this._logger.info(this.constructorName, url, res);
        this.templates = res;
        const defaultType = this.entry.get('identifiable_type').value;
        this.updateListBasedOnType(defaultType);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private post(value: any) {
    if(this.hiringFirm == null) {
      const qst = new QuestionSetTemplate(value).apiData;
      const url = `POST /question_sets_templates`;
      this._questionsSetsTemplateService.post(qst).subscribe(
        (res: IQuestionSet) => {
          this._logger.info(this.constructorName, url, res);

          this.entry.enable();
          this.resetModal(res);

          this._toast.success('Question set template added');
          this._router.navigate(['/templates/question-sets', res.id]);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.errors = err;
          this.entry.enable();
        }
      );
    } else {
      const qs = new QuestionSet(value).apiData;
      const url = `POST /question-sets`;
      this._questionSets.post(qs).subscribe(
        (res: IQuestionSet) => {
          this._logger.info(this.constructorName, url, res);

          this.entry.enable();
          this.resetModal(res);

          this._toast.success('Question set added');
          this._router.navigate(['/question-sets', res.id]);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.errors = err;
          this.entry.enable();
        }
      );
    }
  }
}
