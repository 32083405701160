import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { ICustomNotification } from '@shared/interfaces/custom-notification.interface';
import { EmailNotification } from '@shared/models/email-notification.model';

export class CustomNotification implements ICustomNotification {
  public static readonly TYPE: string = String('CustomNotification');

  public id: APIuuid;
  public type: string;

  public notification_type: string;
  public send_to_root: boolean;
  public send_to_requested: boolean;
  public send_to_assigned: boolean;
  public send_to_custom: boolean;
  public custom_emails: string[] = [];
  public email_notifications: EmailNotification[] = [];

  constructor(partial: Partial<ICustomNotification>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: CustomNotification.TYPE,
        id: this.id,
        attributes: {
          notification_type: this.notification_type,
          send_to_root: this.send_to_root,
          send_to_assigned: this.send_to_assigned,
          send_to_requested: this.send_to_requested,
          send_to_custom: this.send_to_custom,
          custom_emails: this.custom_emails,
          email_notifications: this.email_notifications
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): ICustomNotification {
    const data = res.data;
    if (!(data && data.type === CustomNotification.TYPE)) {
      throw new Error(
        `There was a problem parsing ${CustomNotification.TYPE} API data`
      );
    }

    return new CustomNotification({
      id: data.id,
      ...data.attributes
    });
  }
}
