import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { configurationEnvironment } from '@configuration/environment/environment.configuration';
import { environment } from '@environment/environment';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICard } from '@shared/interfaces/card.interface';
import { ICustomer } from '@shared/interfaces/customer.interface';
import { Card } from '@shared/models/card.model';
import { CardsService } from '@shared/services/cards.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import {
  StripeCardElement,
  StripeElements,
  StripeElementsOptions,
  StripeError,
  Token
} from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';

@Component({
  selector: 'app-modals-cards',
  templateUrl: './modals.cards.component.html'
})
export class ModalsCardsComponent
  extends ModalDirective<ICard>
  implements OnInit
{
  @ViewChild('card-element', { static: true }) public card: StripeCardElement;

  public elements: StripeElements;
  public entry: FormGroup;
  public title = String('MODALS.CARDS.ADD.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');
  public stripePk: string = configurationEnvironment.STRIPE.PUBLISHABLE_KEY;
  public isButtonsDisabled = Boolean(false);

  public elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _toast: ToastService,
    private readonly _cards: CardsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _stripe: StripeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((c: ICustomer) => {
      if (environment.ENVIRONMENT === 'development') {
        this.entry.patchValue(configurationEnvironment.STRIPE.CARD);
      }

      if (c) {
        this.entry.patchValue({
          customer_id: c.id
        });
      }

      Promise.resolve().then(() => {
        this.initCard();
      });
    });
  }

  public onDismiss() {
    this.errors = [];
    this.entry.enable();

    this.resetAll();
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.isButtonsDisabled = true;
      this.entry.disable();
      this.errors = [];

      const url = 'Stripe: Create token';
      this._stripe
        .createToken(this.card, {
          name: value.name
        })
        .subscribe(
          (res: { token?: Token; error?: StripeError }) => {
            this._logger.info(this.constructorName, url, res);

            if (!!res.token) {
              const card = new Card(value);
              card.token = res.token.id;

              if (value.customer_id) {
                card.customer = {
                  id: value.customer_id
                };
              }

              this.postCard(card);
            } else if (!!res.error) {
              const message = res.error.message;

              this._logger.error(this.constructorName, url, message);

              this.errors = [
                {
                  title: 'Card error',
                  detail: message
                }
              ];

              this._toast.error(message);
              this.resetAll();
            }
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);

            this.isButtonsDisabled = false;
            this.entry.enable();
          }
        );
    }
  }

  public resetForm() {
    this.entry.enable();
    super.resetForm();
  }

  protected createForm() {
    this.entry = this._fb.group({
      customer_id: [''],
      name: ['', [Validators.required]]
    });
  }

  private initCard() {
    this._stripe
      .elements(this.elementsOptions)
      .subscribe((e: StripeElements) => {
        this.elements = e;

        if (!this.card) {
          this.card = this.elements.create('card', {
            hidePostalCode: true,
            style: {
              base: {
                fontFamily: '"Open Sans", Arial, Helvetica, sans-serif',
                fontSize: '16px'
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  private postCard(c: ICard) {
    const url = String('POST /cards');
    this._cards.post(c).subscribe(
      (res: ICard) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Card saved');

        this.resetModal(res);
        this.resetAll();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.errors = err;

        this.isButtonsDisabled = false;
        this.entry.enable();
      }
    );
  }

  private resetAll() {
    this.entry.enable();
    this.isButtonsDisabled = false;
  }
}
