import { Component, OnInit, ViewChild } from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { TableColType } from '@shared/enums/table.enum';
import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { ICustomNotification } from '@shared/interfaces/custom-notification.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { ITab } from '@shared/interfaces/tab.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { CustomNotification } from '@shared/models/custom-notification.model';
import { EmailNotification } from '@shared/models/email-notification.model';
import { HiringFirmsCustomNotificationsService } from '@shared/services/hiring-firms/hiring-firms.custom-notifications.service';
import { LoggerService } from '@shared/services/logger.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ToastService } from '@shared/services/toast.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-custom-notifications',
  templateUrl: './modals.custom-notifications.component.html'
})
export class ModalsCustomNotificationsComponent
  extends ModalDirective<IRecruiter>
  implements OnInit
{
  @ViewChild(TabsetComponent, { static: true }) public tabset: TabsetComponent;

  @SessionStorage() private hiringFirm: IHiringFirm;

  public submitKey = String('BUTTONS.SAVE');
  public isLoading = Boolean(true);
  public isButtonsDisabled = Boolean(false);
  public isEmailNotificationDisabled = Boolean(true)
  public entry: FormGroup;
  public rows: IRecruiter[] = [];
  public activeTab = Number(0);
  public notification_type = String('LEADS');
  public title: string;

  public tabs: ITab[] = [
    {
      label: 'TABS.NOTIFICATIONS'
    }
  ];

  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.NAME',
      type: TableColType.Text,
      selector: 'user.full_name',
      selectorFunction: (data: string) => data || '-'
    },
    {
      headerText: 'TABLES.HEADERS.EMAIL',
      type: TableColType.Text,
      selector: 'user.email'
    },
    {
      headerText: 'TABLES.HEADERS.NOTIFICATIONS',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
      type: TableColType.Toggle,
      selector: 'notifications'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _hiringFirmsCustomNotifications: HiringFirmsCustomNotificationsService,
    private readonly _recruiters: RecruitersService,
    private readonly _logger: LoggerService,
    private readonly _toast: ToastService
  ) {
    super();

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  get email_notifications() {
    return this.entry.get('email_notifications') as FormArray;
  }

  addEmailField() {
    this.email_notifications.push(this.createEmailField());
  }

  removeEmailField(index: number) {
    this.email_notifications.removeAt(index);
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((res: ICustomNotification) => {
      this.notification_type = res.notification_type.toUpperCase();
      this.tabs[0].isActive = true;

      if (this.notification_type === 'LEADS') {
        this.tabs.push({ label: 'TABS.ADMINISTRATORS' });
      }

      this.getRecruiters();
      this.getCustomNotifications();
    });
  }

  public onDismiss() {
    this.errors = [];

    this.activeTab = 0;
    this.tabs = [{ label: 'TABS.NOTIFICATIONS', isActive: true }];
  }

  public selectTab(index: number): void {
    this.tabs[index].isActive = true;
    this.activeTab = index;
  }

  public onCheckChanged(): void {
    const isSelectedEmailsChecked = Boolean(
      this.entry.get('send_to_custom').value
    );

    const control: AbstractControl = this.entry.get('custom_emails');
    const leadEmailControl: AbstractControl = this.entry.get('email_notifications');

    if (isSelectedEmailsChecked) {
      if(this.notification_type === "LEADS") {
        leadEmailControl.enable();
        leadEmailControl.setErrors({});
        this.isEmailNotificationDisabled = false;
      } else {
        control.enable();
        control.setValidators([Validators.required]);
        leadEmailControl.disable();
      }
    } else {
      if(this.notification_type === "LEADS") {
        leadEmailControl.disable();
        leadEmailControl.setValidators([]);
        leadEmailControl.setErrors({});
        leadEmailControl.markAsUntouched();
        this.isEmailNotificationDisabled = true;
      } else {
        control.disable();
        control.setValidators([]);
        control.setErrors({});
        control.markAsUntouched();
      }
    }

    control.updateValueAndValidity();
    leadEmailControl.updateValueAndValidity();
  }

  public onButtonClick(_e: Event, r: IRecruiter): void {
    r.notifications = !r.notifications;

    const url = `PATCH /recruiters/${r.id}`;
    this._recruiters.patch(r.apiData).subscribe(
      (res: IRecruiter) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Recruiter updated');
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error('Recruiter update failed');
      }
    );
  }

  public onSubmit({valid, value}: {valid: boolean; value: ICustomNotification;}): void {
    if (valid) {
      this.isButtonsDisabled = true;

      const data: IAPIData = new CustomNotification(value).apiData;
      const id: APIuuid = this.hiringFirm.id;

      const url = `PATCH hiring_firms/${id}/leads_notifications`;
      this._hiringFirmsCustomNotifications.patch(id, data).subscribe(
        (res: ICustomNotification) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success('Notifications updated');
          this.isButtonsDisabled = false;
          this.resetModal();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.isButtonsDisabled = false;
          this._toast.error('Notifications update failed');
        }
      );
    }
  }

  public isHiringLeads() {
    const setting = this.hiringFirm.settings
    return (setting.thank_you_cta === true && setting.lead_generation_type === "hiring")
  }

  protected createForm(): void {
    const setting = this.hiringFirm.settings

    this.entry = this._fb.group({
      notification_type: ['', [Validators.required]],
      send_to_root: [false],
      send_to_assigned: [false],
      send_to_requested: [false],
      send_to_custom: [false],
      custom_emails: [''],
      email_notifications: this._fb.array([this.createEmailField()])
    });

    if (this.isHiringLeads()) {
      const emailNotifications = this._fb.array([this.createEmailField()]);
      this.entry.addControl('email_notifications', emailNotifications);
    }
  }

  private getRecruiters() {
    const url = `GET /recruiters`;

    const filter: any = {
      page_size: 250
    };

    this._recruiters.get(filter).subscribe(
      (res: IRecruiter[]) => {
        this._logger.info(this.constructorName, url, res);
        this.rows = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private getCustomNotifications() {
    const id: APIuuid = this.hiringFirm.id;
    const type = this.notification_type.toLowerCase();

    const url = `GET /hiring_firms/${id}/leads_notifications`;
    this._hiringFirmsCustomNotifications
      .get({
        notification_type: type,
        id
      })
      .subscribe(
        (res: ICustomNotification) => {
          this._logger.info(this.constructorName, url, res);
          this.populateEmailFields(res.email_notifications);

          this.entry.patchValue({
            notification_type: type,
            ...res
          });

          this.onCheckChanged();
          this.isLoading = false;
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.isLoading = false;
        }
      );
  }

  private emailTypeValidator(formGroup: FormGroup): {[key: string]: any} | null {
    const isHiringType = formGroup.get('is_hiring_type').value;
    const isCandidateType = formGroup.get('is_candidate_type').value;
    return (isHiringType || isCandidateType) ? null : { 'typeNotSelected': true };
  }

  private populateEmailFields(emails: EmailNotification[]) {
    const emailFormGroups = emails.map(email => this.createEmailField(email));
    const emailFormArray = this._fb.array(emailFormGroups);
    this.entry.setControl('email_notifications', emailFormArray);
  }

  private createEmailField(email: EmailNotification = new EmailNotification()): FormGroup {
    return this._fb.group({
      email: [email.address, [Validators.required, Validators.email]],
      is_hiring_type: [email.is_hiring_type],
      is_candidate_type: [email.is_candidate_type]
    }, { validators: this.emailTypeValidator });
  }
}
