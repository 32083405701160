import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsERecruit } from '@shared/interfaces/integrations/integrations.erecruit.interface';

export class IntegrationsERecruit implements IIntegrationsERecruit {
  public static readonly TYPE: string = String(
    'ExternalAuthorisations::Erecruit'
  );

  public id: APIuuid;
  public site_url: string;
  public attachment_type_id: string;
  public client_id: string;
  public client_secret: string;
  public ad_source_id: string;
  public folder_group_id: string;
  public status_id: string;
  public requirement_id: string;
  public department_id: string;

  constructor(partial: Partial<IIntegrationsERecruit>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: IntegrationsERecruit.TYPE,
        id: this.id,
        attributes: {
          id: this.id,
          site_url: this.site_url,
          attachment_type_id: this.attachment_type_id,
          client_id: this.client_id,
          client_secret: this.client_secret,
          ad_source_id: this.ad_source_id,
          folder_group_id: this.folder_group_id,
          status_id: this.status_id,
          requirement_id: this.requirement_id,
          department_id: this.department_id
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IIntegrationsERecruit {
    const data = res.data;
    const types = [IntegrationsERecruit.TYPE, 'ExternalAuthorisation'];
    if (!types.includes(data?.type)) {
      throw new Error(
        `There was a problem parsing ${IntegrationsERecruit.TYPE} API data`
      );
    }

    return new IntegrationsERecruit({
      id: data.id,
      ...data.attributes
    });
  }
}
