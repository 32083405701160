import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class ConfigurationNavbarComponent implements OnInit, OnDestroy {
  @SessionStorage() public user: IUser;
  @SessionStorage() public hiringFirm: IHiringFirm;
  @SessionStorage() public settings: ISetting;

  @LocalStorage() public canManageSubAccount: boolean;

  @LocalStorage() private isSubAccount: boolean;

  public links: {
    id: number;
    label: string;
    items: { label: string; icon: string; url: string; step?: number }[];
  }[] = [
    {
      id: 0,
      label: 'NAVBAR.CONFIGURATION',
      items: []
    }
  ];

  public rootLinks: { label: string; icon: string; url: string }[] = [];
  public isProduction: boolean = environment.IS_PRODUCTION;
  public params: any = {
    environment: environment.ENVIRONMENT
  };

  private readonly constructorName: string = String(this.constructor.name);

  private reloadDataSetSubscription: Subscription;

  constructor(
    private readonly _logger: LoggerService,
    private readonly _sidebar: SidebarService
  ) {
    const url = 'Reload sub account dataset';
    this.reloadDataSetSubscription =
      this._sidebar.reloadDatasetCalled$.subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, url, res);

          // REFRESH LINKS HERE
          this.setLinks();
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );
  }

  ngOnInit(): void {
    this.setLinks();
  }

  ngOnDestroy(): void {
    this.reloadDataSetSubscription?.unsubscribe();
  }

  public trackByFn(i: any) {
    return i.id;
  }

  private setLinks() {
    this.settings = this.hiringFirm?.settings;

    this.links[0].items = [
      {
        label: 'NAVBAR.SETTINGS',
        url: '/settings/general',
        icon: 'lnr-cog',
        step: 1
      },
      {
        label: 'NAVBAR.PLUGINS',
        url: '/plugins',
        icon: 'lnr-equalizer',
        step: 2
      },
      {
        label: 'NAVBAR.INTEGRATIONS',
        url: '/integrations',
        icon: 'lnr-network',
        step: 3
      },
      {
        label: 'NAVBAR.USERS',
        url: '/users',
        icon: 'lnr-users2',
        step: 4
      }
    ];

    if (this.settings?.is_demo_enabled) {
      this.links[0].items[0].url = '/settings/billing';
    } else {
      this.links[0].items[0].url = '/settings';
    }

    const isAccountsEnabled = this.links[0].items.find(
      (l: any) => l.label === 'NAVBAR.ACCOUNTS'
    );

    if (
      !isAccountsEnabled &&
      !this.isSubAccount &&
      this.settings?.is_sub_hiring_firm_enabled
    ) {
      this.setAccountsLink();
    } else {
      this.links[0].items = this.links[0].items.filter(
        (l: any) => l.label !== 'NAVBAR.ACCOUNTS'
      );
    }
  }

  private setAccountsLink() {
    this.links[0].items.push({
      label: 'NAVBAR.ACCOUNTS',
      url: '/accounts',
      icon: 'lnr-site-map'
    });
  }
}
