import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsSalesforceIsv } from '@shared/interfaces/integrations/integrations.salesforce-isv.interface';
import { IIntegrationsSalesforce } from '@shared/interfaces/integrations/integrations.salesforce.interface';

export class IntegrationsSalesforceIsv implements IIntegrationsSalesforceIsv {
  public static readonly TYPE: string = String(
    'ExternalAuthorisations::SalesforceIsv'
  );

  public id: APIuuid;
  public site_url: string;

  public get apiData(): IAPIData {
    return {
      data: {
        type: IntegrationsSalesforceIsv.TYPE,
        id: this.id,
        attributes: {
          site_url: this.site_url
        }
      }
    } as IAPIData;
  }

  constructor(partial: Partial<IIntegrationsSalesforce>) {
    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): IntegrationsSalesforceIsv {
    const data = res.data;
    const types = [IntegrationsSalesforceIsv.TYPE, 'ExternalAuthorisation'];

    if (!types.includes(data?.type)) {
      throw new Error(
        `There was a problem parsing ${IntegrationsSalesforceIsv.TYPE} API data`
      );
    }

    return new IntegrationsSalesforceIsv({
      id: data.id,
      ...data.attributes
    });
  }
}
