import { APIuuid } from '@shared/interfaces/api.interface';
import { IIntegrationsBullhorn } from '@shared/interfaces/integrations/integrations.bullhorn.interface';

export class IntegrationsBullhorn implements IIntegrationsBullhorn {
  public id: APIuuid;
  public client_id: string;
  public client_secret: string;
  public username: string;
  public password: string;

  constructor(partial: Partial<IIntegrationsBullhorn>) {
    Object.assign(this, partial);
  }
}
