import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { languages } from '@shared/helpers/languages.helper';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IReferenceRelationship } from '@shared/interfaces/reference-relationship.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ReferenceRelationship } from '@shared/models/reference-relationship.model';
import { LoggerService } from '@shared/services/logger.service';
import { ReferenceRelationshipsService } from '@shared/services/reference-relationships.service';
import { SessionStorage } from 'ngx-webstorage';
import { forkJoin } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-modals-reference-relationships',
  templateUrl: './modals.reference-relationships.component.html'
})
export class ModalsReferenceRelationshipsComponent
  extends ModalDirective<IReferenceRelationship>
  implements OnInit
{
  @SessionStorage() private hiringFirm: IHiringFirm;
  @SessionStorage() private referenceRelationships: IReferenceRelationship[];
  @SessionStorage() private settings: ISetting;

  public title = String('MODALS.REFERENCE_RELATIONSHIPS.TITLE');
  public description = String('MODALS.REFERENCE_RELATIONSHIPS.DESCRIPTION');
  public submitKey = String('BUTTONS.SUBMIT');

  public entry: FormGroup;
  public errors: any = [];
  public languages: any[] = [];
  public isButtonsDisabled = Boolean(false);
  public selectedLanguage: string;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _referenceRelationships: ReferenceRelationshipsService
  ) {
    super();

    this.createItem = this.createItem.bind(this);
  }

  public get formArrayReferenceRelationships(): FormArray {
    return this.entry.get('relationships') as FormArray;
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe(() => {
      this.languages = languages.filter(
        (l: any) => this.settings.languages.indexOf(l.id) > -1
      );

      this.selectedLanguage = this.languages[0].id;

      this.entry.patchValue({
        hiring_firm: this.hiringFirm
      });

      if (this.referenceRelationships.length === 0) {
        this.addItem();
      } else {
        this.referenceRelationships.map((rr: IReferenceRelationship) => {
          if (rr.is_active) {
            this.addItem(rr);
          }
        });
      }
    });
  }

  public addItem(o?: any) {
    const relationships = this.entry.get('relationships') as FormArray;
    relationships.push(this.createItem(o));
  }

  public trackByFn(i: any) {
    return i.id;
  }

  public deleteItem(index: number) {
    const relationships = this.entry.get('relationships') as FormArray;
    const id = relationships.controls[index].get('id').value;

    relationships.removeAt(index);

    if (!!id) {
      this.delete(id);
    }
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      const relationships = value.relationships.filter(
        (r: IReferenceRelationship) => !!r.label
      );

      let url: string;

      const observebales = relationships.map((rr: IReferenceRelationship) => {
        let method = 'post';
        url = `POST /reference_relationships`;

        if (!!rr.id) {
          url = `PATCH /reference_relationships/${rr.id}`;
          method = 'patch';
        }

        return (this._referenceRelationships as any)[method](
          new ReferenceRelationship({
            ...rr,
            hiring_firm: value.hiring_firm
          }).apiData
        );
      });

      forkJoin(observebales).subscribe(
        (res: any[]) => {
          this._logger.info(this.constructorName, url, res);

          this.referenceRelationships = res;
          this.resetModal();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      relationships: this._fb.array([]),
      hiring_firm: this._fb.group({
        id: ['', [Validators.required]]
      })
    });
  }

  private createItem(o?: any) {
    const value = uuid().slice(0, 8);
    return this._fb.group({
      id: [o?.id || '', []],
      language: [o?.language || this.selectedLanguage, [Validators.required]],
      value: [o?.value || value, [Validators.required]],
      label: [o?.label || '', [Validators.required]]
    });
  }

  private delete(id: string) {
    const url = `DELETE /reference_relationships/${id}`;
    this._referenceRelationships.delete(id).subscribe(
      (res: IReferenceRelationship) => {
        this._logger.info(this.constructorName, url, res);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
