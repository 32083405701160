import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html'
})
export class PermissionsComponent implements OnInit {
  public title: string = String('Cookie disabled');
  public detail: string = String('Please enable cookie before using Vitay');

  constructor() {}

  ngOnInit(): void {}
}
