import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { HiringFirmsService } from '@shared/services/hiring-firms.service';
import { LoggerService } from '@shared/services/logger.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-accounts',
  templateUrl: './modals.accounts.component.html'
})
export class ModalsAccountsComponent
  extends ModalDirective<IHiringFirm>
  implements OnInit
{
  @SessionStorage() private hiringFirm: IHiringFirm;

  public title = String('MODALS.ACCOUNTS.ADD.TITLE');
  public description = 'MODALS.ACCOUNTS.ADD.DESCRIPTION';
  public submitKey = String('BUTTONS.SAVE');
  public errors: IAPIErrorObject[] = [];
  public entry: FormGroup;
  public isButtonsDisabled = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _hiringFirms: HiringFirmsService,
    private readonly _fb: FormBuilder,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe(() => {});

    this.createForm();
  }

  public onDismiss() {
    this.errors = [];
    this.entry.enable();
    super.onDismiss();
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }) {
    if (valid) {
      this.isButtonsDisabled = true;

      const id = this.hiringFirm.id;
      const url = `POST /hiring_firms/${id}/children`;
      this._hiringFirms.postChildren(id, value).subscribe(
        (res: IHiringFirm) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal();

          this.isButtonsDisabled = false;
        },
        (err: any) => {
          this._logger.info(this.constructorName, url, err);
          this.isButtonsDisabled = false;
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      hiring_firm: this._fb.group({
        name: ['', [Validators.maxLength(64), Validators.required]]
      }),
      recruiter: this._fb.group({
        first_name: ['', [Validators.maxLength(64), Validators.required]],
        last_name: ['', [Validators.maxLength(64), Validators.required]],
        email: [
          '',
          [Validators.email, Validators.required]
          // [this._emailValidityValidator.validates]
        ],
        is_send_email_enabled: [true]
      })
    });
  }
}
