import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEntry } from '@shared/interfaces/entry.interface';
import { IVerification } from '@shared/interfaces/verification.interface';
import { Experience } from '@shared/models/experience.model';

@Component({
  selector: 'app-entries',
  templateUrl: './entries.component.html'
})
export class EntriesComponent implements OnInit {
  @Input() public entries: IEntry[];
  @Input() public isPublic = Boolean(false);

  @Output() public entriesModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() public verifyModal: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public verificationsModal: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public isWaitingForVerification(e: IEntry) {
    return (
      !e.is_verified &&
      e.verifications.find((v: IVerification) => !v.is_outdated)
    );
  }

  public getTitle(e: IEntry) {
    return e instanceof Experience ? e.company : e.institution;
  }

  public getSubTitle(e: IEntry) {
    return e instanceof Experience
      ? e.position
      : e.degree + (e.subject ? ', ' + e.subject : ' ');
  }

  public openEntriesModal(e: IEntry) {
    this.entriesModal.emit(e);
  }

  public openVerificationsModal(e: IEntry, isVitayVerification?: boolean) {
    if (
      (e.is_verified && this.isPublic) ||
      (e.is_verified && !isVitayVerification)
    ) {
      this.verificationsModal.emit(e);
    } else {
      this.verifyModal.emit(e);
    }
  }
}
