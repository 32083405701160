import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsJobDiva } from '@shared/interfaces/integrations/integrations.jobdiva.interface';
import { IntegrationsJobDiva } from '@shared/models/integrations/integrations.jobdiva.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsJobDivaService {
  private readonly ENDPOINT: string = String(
    `${environment.SERVER_ENDPOINT}/api/services/jobdiva`
  );

  constructor(private readonly _http: HttpClient) {}

  public authorize(
    params: IIntegrationsJobDiva
  ): Observable<IIntegrationsJobDiva> {
    const body = new IntegrationsJobDiva(params).apiData;
    return this._http
      .post<any>(`${this.ENDPOINT}/auth`, body)
      .pipe(map((res: IAPIData) => IntegrationsJobDiva.fromAPI(res)));
  }

  public delete(): Observable<IIntegrationsJobDiva> {
    return this._http
      .delete(this.ENDPOINT)
      .pipe(map((res: IAPIData) => IntegrationsJobDiva.fromAPI(res)));
  }

  public get(type: string, id: string): Observable<any> {
    return this._http.get(`${this.ENDPOINT}/entity`, {
      params: {
        entity_type: type,
        entity_id: id
      }
    });
  }
}
