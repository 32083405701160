import { Component, OnInit } from '@angular/core';
import { EnvironmentsService } from '@configuration/shared/services/environments.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdatesService } from '@shared/services/updates.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _environments: EnvironmentsService,
    private readonly _translate: TranslateService,
    private readonly _updates: UpdatesService
  ) {
    const lng = 'en';
    this._translate.setDefaultLang(lng);
    this._translate.use(lng);

    this._environments.setScripts();
    this._updates.checkUpdates();
  }

  ngOnInit(): void {}
}
