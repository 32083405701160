import { APIuuid } from '@shared/interfaces/api.interface';
import { IIntegrationsJobAdder } from '@shared/interfaces/integrations/integrations.jobadder.interface';

export class IntegrationsJobAdder implements IIntegrationsJobAdder {
  public id: APIuuid;
  public client_id: string;
  public hiring_firm_id: string;
  public redirect_uri: string;

  constructor(partial: Partial<IIntegrationsJobAdder>) {
    Object.assign(this, partial);
  }
}
