import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { QuestionSet } from '@shared/models/question-set.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NIL } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class QuestionSetsService implements IPaginable {
  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/question_sets`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: APIuuid): Observable<IQuestionSet> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V1}/${id}`)
      .pipe(map((res: IAPIData) => QuestionSet.fromAPI(res)));
  }

  public find(id: APIuuid): Observable<IQuestionSet> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}`)
      .pipe(map((res: IAPIData) => QuestionSet.fromAPI(res)));
  }

  public get(filters?: any): Observable<IQuestionSet[]> {
    let params: HttpParams = new HttpParams();

    if (filters) {
      if (filters.page_size) {
        params = params.set(`page[page_size]`, filters.page_size);
      }

      if (filters.type) {
        params = params
        .set(`page[page_size]`, filters.page_size)
        .set(`filter[type]`, filters.type);
      }

      if (filters.filter) {
        params = params.set(
          `filter[${filters.filter.key}]`,
          filters.filter.value
        );
      }
    }

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V1, { params })
      .pipe(map((res: IAPIArrayData) => QuestionSet.fromAPIArray(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    sort?: ISort,
    dates?: IFilter[]
  ): Observable<IQuestionSet[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (dates) {
      dates.map((f: IFilter) => {
        if (f['value']) {
          params = params.set(`dates[${f['key']}]`, f['value']);
        }
      });
    }

    if (sort) {
      const sortValue = sort['isReverse'] ? `-${sort['value']}` : sort['value'];
      params = params.set('sort', sortValue);
    }

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V1, { params })
      .pipe(
        tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
        map((res: IAPIArrayData) => QuestionSet.fromAPIArray(res))
      );
  }

  public patch(body: IAPIData): Observable<IQuestionSet> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => QuestionSet.fromAPI(res)));
  }

  public post(body: any): Observable<IQuestionSet> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V1, body)
      .pipe(map((res: IAPIData) => QuestionSet.fromAPI(res)));
  }

  public duplicate(id: APIuuid): Observable<IQuestionSet> {
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V1}/${id}/duplicate`, NIL)
      .pipe(map((res: IAPIData) => QuestionSet.fromAPI(res)));
  }
}
