import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICard } from '@shared/interfaces/card.interface';
import { ICustomer } from '@shared/interfaces/customer.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPlan } from '@shared/interfaces/plan.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ISubscription } from '@shared/interfaces/subscription.interface';
import { Card } from '@shared/models/card.model';
import { Customer } from '@shared/models/customer.model';
import { Subscription } from '@shared/models/subscription.model';
import { CardsService } from '@shared/services/cards.service';
import { LoggerService } from '@shared/services/logger.service';
import { NavbarService } from '@shared/services/navbar.service';
import { SubscriptionsService } from '@shared/services/subscriptons.service';
import {
  StripeCardElement,
  StripeElements,
  StripeElementsOptions,
  StripeError,
  Token
} from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-subscriptions',
  templateUrl: './modals.subscriptions.component.html'
})
export class ModalsSubscriptionsComponent
  extends ModalDirective<{ IPlan: IPlan; ICustomer: ICustomer }>
  implements OnInit
{
  @ViewChild('card-element', { static: true }) public card: StripeCardElement;

  @Output() public subscriptionChanges: EventEmitter<any> = new EventEmitter();

  @SessionStorage() private hiringFirm: IHiringFirm;
  @SessionStorage() private settings: ISetting;

  public elements: StripeElements;

  public entry: FormGroup;
  public isButtonsDisabled = Boolean(false);
  public isProfessionalPlan = Boolean(false);
  public title: string;
  public submitKey = String('BUTTONS.SUBMIT');
  public customer: Partial<ICustomer>;
  public plan: IPlan;

  public elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _cards: CardsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _navbar: NavbarService,
    private readonly _subscriptions: SubscriptionsService,
    private readonly _stripe: StripeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((res: { plan: IPlan; customer: ICustomer }) => {
      if (res) {
        this.customer = res.customer;

        if (!this.customer || !this.customer.default_source) {
          const cardFormGroup = this.entry.get('card') as FormGroup;

          cardFormGroup['controls']['name'].setValidators([
            Validators.required
          ]);
          cardFormGroup['controls']['name'].updateValueAndValidity();
        } else {
          const planFormGroup = this.entry.get('plan') as FormGroup;

          planFormGroup['controls']['customer_id'].setValidators([
            Validators.required
          ]);
          planFormGroup['controls']['customer_id'].updateValueAndValidity();
        }

        this.plan = res.plan;

        this.entry.patchValue({
          plan: {
            customer_id: res.customer ? res.customer.id : null,
            plan_id: res.plan ? res.plan.id : null
          }
        });
      }

      Promise.resolve().then(() => {
        this.initCard();
      });
    });
  }

  public onDismiss() {
    this.entry.enable();
    this.errors = [];

    super.onDismiss();
  }

  public onSubmit({ valid, value }: FormGroup): void {
    const customerId = value.plan.customer_id;

    this.isButtonsDisabled = true;
    if (valid) {
      this.errors = [];

      // Submit subscription
      if (!!customerId && !!this.customer.default_source) {
        this.postSubscription(value);
      } else {
        // Create customer and card
        this.createToken(value);
      }
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      card: this._fb.group({
        name: ['']
      }),
      plan: this._fb.group({
        customer_id: [''],
        plan_id: ['', [Validators.required]]
      })
    });
  }

  private postSubscription(value: any) {
    const data = new Subscription(value.plan).apiData;

    const url = 'POST /subscriptions';
    this._subscriptions.post(data).subscribe(
      (res: ISubscription) => {
        this._logger.info(this.constructorName, url, res);

        if (this.settings.is_demo_enabled) {
          this.settings.is_demo_enabled = true;
          this.hiringFirm = {
            ...this.hiringFirm,
            settings: this.settings
          };

          this._navbar.toggleSetting({
            label: 'is_demo_enabled',
            value: true
          });
        }

        this.resetModal();
        this.subscriptionChanges.emit();
        this.isButtonsDisabled = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.entry.enable();
        this.isButtonsDisabled = false;
        this.subscriptionChanges.emit();
        this.errors = err;
      }
    );
  }

  private createToken(value: any) {
    const url = 'Stripe: Create token';
    this._stripe.createToken(this.card, value.card).subscribe(
      (res: { token?: Token; error?: StripeError }) => {
        this._logger.info(this.constructorName, url, res);

        if (!!res.token) {
          const card = new Card(value);
          card.token = res.token.id;

          if (this.customer) {
            card.customer = {
              id: this.customer.id
            };
          }

          this.postCard(card);
          this.isButtonsDisabled = false;
        } else if (!!res.error) {
          const message = res.error.message;

          this._logger.error(this.constructorName, url, message);

          this.errors = [
            {
              title: 'Card error',
              detail: message
            }
          ];

          this.isButtonsDisabled = false;
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }

  private postCard(c: ICard) {
    const url = 'POST /cards';
    this._cards.post(c).subscribe(
      (res: ICard) => {
        this._logger.info(this.constructorName, url, res);

        if (res.customer) {
          this.customer = new Customer({
            id: res.customer.id,
            default_source: res.id
          });

          this.entry.patchValue({
            plan: {
              customer_id: res.customer.id
            }
          });
        }

        this.subscriptionChanges.emit();
        this.isButtonsDisabled = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isButtonsDisabled = false;
        this.subscriptionChanges.emit();
      }
    );
  }

  private initCard() {
    this._stripe
      .elements(this.elementsOptions)
      .subscribe((e: StripeElements) => {
        this.elements = e;

        if (!this.card) {
          this.card = this.elements.create('card', {
            hidePostalCode: true,
            style: {
              base: {
                fontFamily: '"Open Sans", Arial, Helvetica, sans-serif',
                fontSize: '16px'
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }
}
