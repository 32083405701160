import { NgModule } from '@angular/core';
import { ModalsAccountsComponent } from '@configuration/shared/components/modals/accounts/modals.accounts.component';
import { ModalsCardsComponent } from '@configuration/shared/components/modals/cards/modals.cards.component';
import { ModalsCustomNotificationsComponent } from '@configuration/shared/components/modals/custom-notifications/modals.custom-notifications.component';
import { ModalsIntegrationsComponent } from '@configuration/shared/components/modals/integrations/modals.integrations.component';
import { ModalsReferenceRelationshipsComponent } from '@configuration/shared/components/modals/reference-relationships/modals.reference-relationships.component';
import { ModalsSubscriptionsComponent } from '@configuration/shared/components/modals/subscriptions/modals.subscriptions.component';
import { ConfigurationNavbarComponent } from '@configuration/shared/components/navbar/navbar.component';
import { ConfigurationTopbarComponent } from '@configuration/shared/components/topbar/topbar.component';
import { SharedModule } from '@shared/shared.module';

const components = [
  ConfigurationNavbarComponent,
  ConfigurationTopbarComponent,

  // Modals
  ModalsAccountsComponent,
  ModalsCardsComponent,
  ModalsIntegrationsComponent,
  ModalsReferenceRelationshipsComponent,
  ModalsSubscriptionsComponent,
  ModalsCustomNotificationsComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [SharedModule, components],
  declarations: [components]
})
export class ConfigurationSharedModule {}
