import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreRoutingModule } from '@configuration/core/core-routing.module';
import { configurationEnvironment } from '@configuration/environment/environment.configuration';
import { ConfigurationSharedModule } from '@configuration/shared/shared.module';
import { environment } from '@environment/environment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AirbrakeErrorHandler } from '@shared/handlers/airbrake-error.handler';
import { InterceptorService } from '@shared/services/interceptor.service';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxMaskModule } from 'ngx-mask';
import { PixelModule } from 'ngx-pixel';
import { QuillModule } from 'ngx-quill';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxWebstorageModule } from 'ngx-webstorage';

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  imports: [
    ConfigurationSharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreRoutingModule,
    HttpClientModule,
    ProgressbarModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    PixelModule.forRoot({
      enabled: environment.FB_PIXEL.IS_ENABLED,
      pixelId: environment.FB_PIXEL.PIXEL_ID
    }),
    QuillModule.forRoot(),
    NgxStripeModule.forRoot(configurationEnvironment.STRIPE.PUBLISHABLE_KEY),
    ModalModule.forRoot(),
    NgCircleProgressModule.forRoot({
      animation: false,
      renderOnClick: false,
      titleColor: '#000000',
      innerStrokeColor: 'rgba(0, 0, 0, 0)',
      space: -3.5,
      outerStrokeWidth: 3,
      showSubtitle: false,
      outerStrokeLinecap: 'round',
      lazy: false
    }),
    UiSwitchModule.forRoot({
      size: 'small',
      color: '#21CE99',
      defaultBgColor: '#FF2721',
      defaultBoColor: 'rgba(0, 0, 0, 0.1)'
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'configuration.vitay.io'
    }),
    LoggerModule.forRoot({
      // serverLoggingUrl: '/logs',
      level: environment.IS_PRODUCTION
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG
      // serverLogLevel: NgxLoggerLevel.ERROR
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      toastClass: 'ngx-toastr v-toastr',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [
    CookieService,
    CustomPreloadingStrategy,
    {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [more, drilldown]
    }
  ]
})
export class CoreModule {}
