import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { ISubscription } from '@shared/interfaces/subscription.interface';
import { Subscription } from '@shared/models/subscription.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/subscriptions`
  );

  constructor(private readonly _http: HttpClient) {}

  public get(params?: HttpParams): Observable<ISubscription[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3, {
        params
      })
      .pipe(map((res: IAPIArrayData) => Subscription.fromAPIArray(res)));
  }

  public find(id?: APIuuid): Observable<ISubscription> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => Subscription.fromAPI(res)));
  }

  public post(body: any): Observable<ISubscription> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => Subscription.fromAPI(res)));
  }
}
